import React, { useState, useEffect } from "react";
import PatienDashboard from './PatientDashboard';
import VitalOverview from "../Report/VitalHistory";

const VisitReport = () => {
    const [visitReports, setVisitReports] = useState([]);
    // const [patientsData, setPatientsData] = useState([]);


    const [newVisitReport, setNewVisitReport] = useState({
        patientName: "",
        dateOfVisit: "",
        reasonForVisit: "",
        doctor: "",
        summary: "",
        treatment: "",
        followUpInstructions: "",
    });

    const handleChange = (event) => {
        setNewVisitReport({
            ...newVisitReport,
            [event.target.name]: event.target.value,
        });
    };

    const addVisitReport = (event) => {
        event.preventDefault();
        setVisitReports([...visitReports, newVisitReport]);
        setNewVisitReport({
            patientName: "",
            dateOfVisit: "",
            reasonForVisit: "",
            doctor: "",
            summary: "",
            treatment: "",
            followUpInstructions: "",
        });
    };

    // useEffect(() => {
    //     // Fetch patient data from API
    //     fetch('https://boafo.com/api/v1/patient')
    //         .then(response => response.json())
    //         .then(data => setPatientsData(data))
    //         .catch(error => console.log(error));
    // }, []);

    const patients = [
        {
            id: 1, name: 'Mekenna Boafo',
            photo: './img/mekenna.jpeg',
            dob: '12/24/1980',
            phone: '(555) 5555-5550',
            appointment: {
                date: '02-21 2:00pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'pending',
                reason: 'General Care',
                stage: 'apt'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                },
                {
                    lab_name: 'Fish allergy',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2024-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'salad',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'Beans',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [
                {
                    "record_date": "2023-01-01",
                    "weight": "170",
                    "heartRate": "80",
                    "bloodPressure": "120/80",
                    "temperature": "98.6°F",
                    "regressionLine": 78 // Example regression value for the weight on that date
                },
                {
                    "record_date": "2023-01-02",
                    "weight": "171",
                    "heartRate": "82",
                    "bloodPressure": "122/81",
                    "temperature": "98.7°F",
                    "regressionLine": 79 // Example regression value for the weight
                }
            ],
            visits: [
                {
                    date: '2022-01-01',
                    reason: 'Constipation',
                    summary: 'Not eating enough salad',
                    prescription: 'salad 1 leaf per meal'
                },
                {
                    date: '2021-10-15',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }],
            vaccine: [
                {
                    date: '2022-01-01',
                    name: 'TB',
                    dosage: '1v',
                    next_due: '1/20/2026',
                    administration_route: 'administration_route',
                    side_effect: 'None',
                    summary: 'toughed it out',

                },
                {
                    date: '2022-01-01',
                    name: 'Pre-teen',
                    dosage: '2v',
                    next_due: '1//1/2033',
                    administration_route: 'administration_route',
                    side_effect: 'None',
                    summary: 'wanted a pill',
                }],
            allergy: [{
                name: 'test',
                reaction: 'None',
                severity: 'n/a',
                record_date: '2024-01-25',
                note: 'test'
            }]

        },
        {
            id: 2,
            name: 'Annalise Boafo',
            photo: './img/profile.jpeg',
            dob: '08/10/1994',
            phone: '(555) 5555-5552',
            appointment: {
                date: '04-30  1:00pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'confirm',
                reason: 'Runny eye',
                stage: 'consultation'
            },
            vitals: [
                {
                  "record_date": "2023-01-01",
                  "weight": "170",
                  "heartRate": "80",
                  "bloodPressure": "120/80",
                  "temperature": "98.6°F",
                  "regressionLine": 78 // Example regression value for the weight
                },
                {
                  "record_date": "2023-01-02",
                  "weight": "171",
                  "heartRate": "82",
                  "bloodPressure": "122/81",
                  "temperature": "98.7°F",
                  "regressionLine": 79 // Example regression value for the weight
                }
              ],
            lab: [
                {
                    lab_name: 'Stomach virus',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2024-01-25',
                    note: 'test'
                },
                {
                    lab_name: 'falling test',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2024-01-25',
                    note: 'test'
                },
                {
                    lab_name: 'food test',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2024-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'chockolate',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'bread',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/7d',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            visits: [
                {
                    date: '2022-02-10',
                    reason: 'Excessive fluid in eye',
                    summary: 'Crying',
                    prescription: 'candy, 1 tablet per month'
                },
                {
                    date: '2021-11-20',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }],
            vaccine: [
                {
                    date: '2022-01-01',
                    name: 'Yellow Fever',
                    dosage: '2v',
                    next_due: '1/5/2040',
                    administration_route: 'administration_route',
                    side_effect: 'None',
                    summary: 'like a champ',

                },
                {
                    date: '2022-01-01',
                    name: 'Constipation',
                    dosage: '2v',
                    next_due: '0',
                    administration_route: 'administration_route',
                    side_effect: 'None',
                    summary: 'yelled',
                }],
            allergy: [{
                name: 'test',
                reaction: 'None',
                severity: 'n/a',
                record_date: 'none',
                note: 'test'
            }]
        },
        {
            id: 20,
            name: 'Phoenix Boafo',
            photo: './img/ph.jpeg',
            dob: '10/01/2023',
            phone: '(555) 5555-5520',
            appointment: {
                date: '04-30  1:00pm',
                type: 'In-arm',
                doctor: 'Dr Ama Danso',
                status: 'confirm',
                reason: 'Runny eye',
                stage: 'consultation'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'tomato',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/7d',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'green',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [
                {
                    "record_date": "2023-01-01",
                    "weight": "170",
                    "heartRate": "80",
                    "bloodPressure": "120/80",
                    "temperature": "98.6°F",
                    "regressionLine": 78 // Example regression value for the weight on that date
                },
                {
                    "record_date": "2023-01-02",
                    "weight": "171",
                    "heartRate": "82",
                    "bloodPressure": "122/81",
                    "temperature": "98.7°F",
                    "regressionLine": 79 // Example regression value for the weight
                }
            ],
            visits: [
                {
                    date: '2022-02-10',
                    reason: 'Heavy Diaper load',
                    summary: 'Crying',
                    prescription: 'Change diaper when full'
                },
                {
                    date: '2021-11-20',
                    reason: 'Excessive hugging',
                    summary: 'Normal health',
                    prescription: 'hug back and say more hug'
                }
            ],
            vaccine: [],
            allergy: []
        },
        {
            id: 3,
            name: 'Kofi allgood',
            photo: 'https://randomuser.me/api/portraits/men/3.jpg',
            dob: '01/10/1992',
            phone: '(555) 5555-5554',
            appointment: {
                date: '04-30  12:00pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Neck pain',
                stage: 'consultation'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'para',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'juice',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '195/120',
                temperature: '105.4°F',
                heartRate: '68bpm',
                weight: '320lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-01-25',
                    reason: 'Sore throat',
                    summary: 'Viral pharyngitis',
                    prescription: 'Throat lozenges, Gargling with warm water'
                },
                {
                    date: '2021-09-01',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }
            ],
            vaccine: [],
            allergy: []
        },
        {
            id: 4,
            name: 'Ajoa Hardcore',
            photo: 'https://randomuser.me/api/portraits/women/4.jpg',
            dob: '01/12/1995',
            phone: '(555) 5555-5556',
            appointment: {
                date: '12-01 3:00pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'confirmed',
                reason: 'Cant sleep',
                stage: 'consultation'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'yam',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'fish',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '120/80',
                temperature: '98.9°F',
                heartRate: '111bpm',
                weight: '220lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-01-15',
                    reason: 'Headache',
                    summary: 'Tension headache',
                    prescription: 'Acetaminophen 500mg, Rest'
                },
                {
                    date: '2021-12-05',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }
            ],
            vaccine: [],
            allergy: [{
                name: 'test',
                reaction: 'None',
                severity: 'n/a',
                record_date: 'none',
                note: 'test'
            }]
        },
        {
            id: 5, name: 'Kwabena Selfie',
            photo: 'https://randomuser.me/api/portraits/men/6.jpg',
            dob: '04/15/1998',
            phone: '(555) 5555-5557',
            appointment: {
                date: '04-30  2:30pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Hungry',
                stage: 'apt'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'olive',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/7d',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'oil',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '120/80',
                temperature: '98.6°F',
                heartRate: '70bpm',
                weight: '420lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-01-01',
                    reason: 'Cough and cold',
                    summary: 'Upper respiratory infection',
                    prescription: 'Ibuprofen 400mg, Sudafed'
                },
                {
                    date: '2021-10-15',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }],
            vaccine: [],
            allergy: []
        },
        {
            id: 6,
            name: 'Yaa Oscar',
            photo: 'https://randomuser.me/api/portraits/women/7.jpg',
            dob: '05/10/2004',
            phone: '(555) 5555-5558',
            appointment: {
                date: '04-30  12:10pm',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Too Lazy',
                stage: 'apt'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'gari',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'Beans',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/30d',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '130/90',
                temperature: '99.1°F',
                heartRate: '72bpm',
                weight: '100lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-02-10',
                    reason: 'Back pain',
                    summary: 'Muscle strain',
                    prescription: 'Naproxen 500mg, Stretching exercises'
                },
                {
                    date: '2021-11-20',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }
            ],
            vaccine: [],
            allergy: []
        },
        {
            id: 7,
            name: 'Yaw Tog',
            photo: 'https://randomuser.me/api/portraits/men/8.jpg',
            dob: '02/10/2015',
            phone: '(555) 5555-5559',
            appointment: {
                date: '04-30  11:00am',
                type: 'Tele-health',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Bored',
                stage: 'apt'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'sugar',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'sleep',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '2/7d',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '125/80',
                temperature: '98.4°F',
                heartRate: '68bpm',
                weight: '300lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-01-25',
                    reason: 'Sore throat',
                    summary: 'Viral pharyngitis',
                    prescription: 'Throat lozenges, Gargling with warm water'
                },
                {
                    date: '2021-09-01',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }
            ],
            vaccine: [],
            allergy: []
        },
        {
            id: 8,
            name: 'Ajoa Pinky',
            photo: 'https://randomuser.me/api/portraits/women/9.jpg',
            dob: '09/10/2004',
            phone: '(555) 5555-5523',
            appointment: {
                date: '04-30  10:30pm',
                type: 'In-person',
                doctor: 'Dr Abena Lee',
                status: 'reject',
                reason: 'Excessive gas',
                stage: 'consultation'
            },
            lab: [
                {
                    lab_name: 'COVID19',
                    lab_result: 'Negative',
                    unit: 'n/a',
                    reference_range: '10-100',
                    date: '2022-01-25',
                    note: 'test'
                }],
            prescription: [
                {
                    prescribed_medicine: 'fanta',
                    prescribed_date: '2025-01-01',
                    dosage: '50ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                },
                {
                    prescribed_medicine: 'Rice',
                    prescribed_date: '2024-01-01',
                    dosage: '750ml',
                    frequency: '1/24h',
                    duration: '1 week',
                    note: 'From a doctor'
                }],
            vitals: [{
                bloodPressure: '120/80',
                temperature: '98.9°F',
                heartRate: '71bpm',
                weight: '122lb',
                record_date: '2/30/2025'
            }],
            visits: [
                {
                    date: '2022-01-15',
                    reason: 'Headache',
                    summary: 'Tension headache',
                    prescription: 'Acetaminophen 500mg, Rest'
                },
                {
                    date: '2021-12-05',
                    reason: 'Annual checkup',
                    summary: 'Normal health',
                    prescription: ''
                }
            ],
            vaccine: [],
            allergy: []
        }
    ];



    return (
        <div style={{
            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
            padding: '2px',
            position: "flex"
        }}>
            <PatienDashboard patients={patients} />
        </div>
    );
};

export default VisitReport;

