import React, { useState, useRef } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Container, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MedicationLiquid, Coronavirus } from "@mui/icons-material";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ScienceIcon from '@mui/icons-material/Science';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MedicationManager from './Medication';
import LabConsultation from './Lab';
import VaccinationConsultation from "./Vaccination";
import AllergyConsultation from './Allergy';
import Dictation from './Dictation';
import styled from 'styled-components';
import { style } from "@mui/system";

// Styled Components
const StyledListItem = styled(ListItem)`
  background-color: ${(props) => (props.isSelected ? '#e1f5fe' : '#f7feff')};  /* Highlight when selected */
  border: 0.5px solid #343a40;
  border-radius: 2%;
  color: black;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-bottom: 10px; /* Gap between tasks */
  
  &:hover {
    background-color: #e1f5fe;
    cursor: pointer;
  }
`;

const StyledContainer = styled(Container)`
  margin-top: 2vh;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 12px;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 16px;
  font-weight: 600;
`;

const CollapseContainer = styled(List)`
  color: #787878;
  padding-left: 20px;
`;

const Consulation = ({ patient }) => {
  // Individual section open states
  const [openDictation, setOpenDictation] = useState(false);
  const [openMedication, setOpenMedication] = useState(false);
  const [openLabwork, setOpenLabwork] = useState(false);
  const [openVaccination, setOpenVaccination] = useState(false);
  const [openAllergy, setOpenAllergy] = useState(false);

  // Refs for each section
  const dictationRef = useRef(null);
  const medicationRef = useRef(null);
  const labworkRef = useRef(null);
  const vaccinationRef = useRef(null);
  const allergyRef = useRef(null);

  // Handle toggling the visibility of each section
  const handleSectionClick = (section) => {
    // Close all sections if clicked section is already open
    if (section === 'dictation' && openDictation) {
      setOpenDictation(false);
    } else if (section === 'medication' && openMedication) {
      setOpenMedication(false);
    } else if (section === 'labwork' && openLabwork) {
      setOpenLabwork(false);
    } else if (section === 'vaccination' && openVaccination) {
      setOpenVaccination(false);
    } else if (section === 'allergy' && openAllergy) {
      setOpenAllergy(false);
    } else {
      // Otherwise, open the clicked section
      setOpenDictation(section === 'dictation');
      setOpenMedication(section === 'medication');
      setOpenLabwork(section === 'labwork');
      setOpenVaccination(section === 'vaccination');
      setOpenAllergy(section === 'allergy');
    }

    // Scroll the clicked section into view when expanded
    if (section === 'dictation' && dictationRef.current) {
      dictationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (section === 'medication' && medicationRef.current) {
      medicationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (section === 'labwork' && labworkRef.current) {
      labworkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (section === 'vaccination' && vaccinationRef.current) {
      vaccinationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (section === 'allergy' && allergyRef.current) {
      allergyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <StyledContainer maxwidthlg>
      <List disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Dictation Section */}
        <StyledListItem
          button
          onClick={() => handleSectionClick('dictation')}
          isSelected={openDictation}  // Highlight selected section
        >
          <ListItemIcon sx={{ color: '#50C878' }}>
            <MedicalServicesIcon />
          </ListItemIcon>
          {openDictation ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Dictation" />}
          {openDictation ? <> <ExpandLess /> </> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={openDictation} timeout="auto" unmountOnExit>
          <CollapseContainer ref={dictationRef}>
            <Dictation patient={patient} />
          </CollapseContainer>
        </Collapse>

        {/* Medication Section */}
        <StyledListItem
          button
          onClick={() => handleSectionClick('medication')}
          isSelected={openMedication}  // Highlight selected section
        >
          <ListItemIcon sx={{ color: 'red' }}>
            <MedicationLiquid />
          </ListItemIcon>
          {openMedication ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Medication" />}
          {openMedication ? <> <ExpandLess /> </> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={openMedication} timeout="auto" unmountOnExit>
          <CollapseContainer ref={medicationRef}>
            <MedicationManager patient={patient} />
          </CollapseContainer>
        </Collapse>

        {/* Labwork Section */}
        <StyledListItem
          button
          onClick={() => handleSectionClick('labwork')}
          isSelected={openLabwork}  // Highlight selected section
        >
          <ListItemIcon sx={{ color: 'orange' }}>
            <ScienceIcon />
          </ListItemIcon>
          {openLabwork ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Lab Work" />}
          {openLabwork ? <> <ExpandLess /> </> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={openLabwork} timeout="auto" unmountOnExit>
          <CollapseContainer ref={labworkRef}>
            <LabConsultation patient={patient} />
          </CollapseContainer>
        </Collapse>

        {/* Vaccination Section */}
        <StyledListItem
          button
          onClick={() => handleSectionClick('vaccination')}
          isSelected={openVaccination}  // Highlight selected section
        >
          <ListItemIcon sx={{ color: '#318CE7' }}>
            <VaccinesIcon />
          </ListItemIcon>
          {openVaccination ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Vaccination" />}
          {openVaccination ? <> <ExpandLess /> </> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={openVaccination} timeout="auto" unmountOnExit>
          <CollapseContainer ref={vaccinationRef}>
            <VaccinationConsultation patient={patient} />
          </CollapseContainer>
        </Collapse>

        {/* Allergy Section */}
        <StyledListItem
          button
          onClick={() => handleSectionClick('allergy')}
          isSelected={openAllergy}  // Highlight selected section
        >
          <ListItemIcon>
            <Coronavirus />
          </ListItemIcon>
          {openAllergy ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Allergy" />}
          {openAllergy ? <> <ExpandLess /> </> : <ExpandMore />}        </StyledListItem>
        <Collapse in={openAllergy} timeout="auto" unmountOnExit>
          <CollapseContainer ref={allergyRef}>
            <AllergyConsultation patient={patient} />
          </CollapseContainer>
        </Collapse>
      </List>
    </StyledContainer>
  );
};

export default Consulation;

