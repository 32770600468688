// SPCChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const SPCChart = ({ patient }) => {
  const { vitals } = patient;
  const chartData = {
    labels: vitals.timestamps,
    datasets: [{
      label: 'Heart Rate',
      data: vitals.values,
      borderColor: 'rgba(255, 99, 132, 1)',
      fill: false,
    }, {
      label: 'Upper Control Limit',
      data: vitals.upperLimit,
      borderColor: 'rgba(255, 159, 64, 1)',
      borderDash: [5, 5],
      fill: false,
    }, {
      label: 'Lower Control Limit',
      data: vitals.lowerLimit,
      borderColor: 'rgba(255, 159, 64, 1)',
      borderDash: [5, 5],
      fill: false,
    }]
  };

  return (
    <div className="spc-chart">
      <h4>SPC Control Chart</h4>
      <Line data={chartData} />
    </div>
  );
};

export default SPCChart;

