import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { IconButton, Tooltip, Button, ButtonGroup } from '@mui/material';
import { FitnessCenter, HeartBroken, TrendingUp, Thermostat, ExpandMore, ExpandLess } from '@mui/icons-material';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const TrendChart = ({ patient }) => {
  const { vitals } = patient;

  // State to manage selected vital and chart visibility
  const [selectedVital, setSelectedVital] = useState('heartRate'); // Default to 'heartRate'
  const [isChartVisible, setIsChartVisible] = useState(false); // Toggle chart visibility

  // Handle chart visibility toggle
  const toggleChartVisibility = () => {
    setIsChartVisible((prevState) => !prevState);
  };

  // Handle vital selection and set the selected vital's data for the chart
  const handleVitalSelection = (vital) => {
    setSelectedVital(vital);
  };

  // Function to retrieve data based on the selected vital
  const getVitalData = (vital) => vitals.map((vitalEntry) => {
    if (vital === 'heartRate') {
      return parseInt(vitalEntry.heartRate, 10);
    }
    if (vital === 'bloodPressure') {
      return parseInt(vitalEntry.bloodPressure.split('/')[0], 10); // Systolic value
    }
    if (vital === 'temperature') {
      return parseFloat(vitalEntry.temperature.replace('°F', ''));
    }
    if (vital === 'weight') {
      return parseFloat(vitalEntry.weight);
    }
    return 0;
  });

  // Color mapping for each vital for the chart
  const chartColors = {
    heartRate: '#64B5F6', // Blue for heartRate
    bloodPressure: '#81C784', // Green for bloodPressure
    temperature: '#FFD54F', // Yellow for temperature
    weight: '#FF7043', // Orange for weight
  };

  // Chart data dynamically changes based on selected vital
  const chartData = {
    labels: vitals.map((vital) => vital.record_date), // X-axis (Dates)
    datasets: [{
      label: `${selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1)} Trend`, // Dynamic label
      data: getVitalData(selectedVital), // Data based on selected vital
      borderColor: chartColors[selectedVital], // Dynamic border color based on vital
      backgroundColor: chartColors[selectedVital] + '40', // 25% opacity for background
      fill: true,
    }]
  };

  // Chart options with dynamic Y-axis labels and tooltips
  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1), // Y-axis label based on selected vital
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            const label = tooltipItem.dataset.label;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div>
      {/* Chart Visibility Toggle */}
      <Tooltip title={isChartVisible ? "Hide Trend Chart" : "Show Trend Chart"} arrow>
        <Button
          onClick={toggleChartVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isChartVisible ? <ExpandLess /> : <ExpandMore />} Vital Trending Chart
        </Button>
      </Tooltip>

      {/* Vital Selection Buttons with Highlighting */}
      {isChartVisible && (
        <div style={{ marginBottom: '20px' }}>
          <ButtonGroup variant="outlined" color="primary">
            <Button
              onClick={() => handleVitalSelection('heartRate')}
              style={{
                backgroundColor: selectedVital === 'heartRate' ? chartColors.heartRate : 'transparent',
                color: selectedVital === 'heartRate' ? 'white' : 'initial',
              }}
            >
              Heart Rate
            </Button>
            <Button
              onClick={() => handleVitalSelection('bloodPressure')}
              style={{
                backgroundColor: selectedVital === 'bloodPressure' ? chartColors.bloodPressure : 'transparent',
                color: selectedVital === 'bloodPressure' ? 'white' : 'initial',
              }}
            >
              Blood Pressure
            </Button>
            <Button
              onClick={() => handleVitalSelection('temperature')}
              style={{
                backgroundColor: selectedVital === 'temperature' ? chartColors.temperature : 'transparent',
                color: selectedVital === 'temperature' ? 'white' : 'initial',
              }}
            >
              Temperature
            </Button>
            <Button
              onClick={() => handleVitalSelection('weight')}
              style={{
                backgroundColor: selectedVital === 'weight' ? chartColors.weight : 'transparent',
                color: selectedVital === 'weight' ? 'white' : 'initial',
              }}
            >
              Weight
            </Button>
          </ButtonGroup>
        </div>
      )}
      {/* Trend Chart */}
      {isChartVisible && (
        <div style={{ marginTop: '30px' }}>
          <h6>{selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1)} Trend</h6>
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default TrendChart;
