import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    }}>
      <Button
        variant="contained"
        onClick={() => loginWithRedirect()}
        sx={{
          backgroundColor: '#007bff', // Primary color
          color: '#fff',
          fontSize: '1rem',
          fontWeight: 600,
          borderRadius: '8px',
          padding: '10px 20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, transform 0.2s',
          '&:hover': {
            backgroundColor: '#0056b3', // Darker primary color for hover
            transform: 'scale(1.05)',
          },
          '&:active': {
            transform: 'scale(1)',
            boxShadow: 'none',
          }
        }}
      >
        Log In
      </Button>
    </Box>
  );
};

export default LoginButton;
