import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Container, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

// Styled Components
const StyledAppBar = styled(AppBar)`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  background-color: #f0f0f0;
`;

const Logo = styled.img`
  max-height: 40px;
  margin-right: 20px;
`;

const StyledBox = styled(Box)`
  flex-grow: 1;
  display: ${({ hideOnSmallScreens }) => (hideOnSmallScreens ? 'flex' : 'none')};

  @media (max-width: 768px) {
    display: ${({ hideOnSmallScreens }) => (hideOnSmallScreens ? 'none' : 'flex')};
  }
`;

const AvatarButton = styled(IconButton)`
  padding: 0;
`;

const ProfileMenuStyled = styled(Menu)`
  margin-top: 45px;
`;

const Header = () => {
    const { user } = useAuth0();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const ProfileMenu = [
        { menu: 'Account', endpoint: '/account' },
        { menu: 'Billing', endpoint: '/billing' },
        { menu: 'Resource', endpoint: '/resource' },
        { menu: 'Logout', endpoint: '/logout' }
    ];

    return (
        <StyledAppBar position="fixed" color="inherit">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/">
                        <Logo src='/img/logo.png' alt="Boafo" />
                    </Link>

                    <StyledBox hideOnSmallScreens>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </StyledBox>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <AvatarButton onClick={handleOpenUserMenu}>
                                <Avatar alt={user.name} src={user.picture} />
                            </AvatarButton>
                        </Tooltip>
                        <ProfileMenuStyled
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {ProfileMenu.map(({ menu, endpoint }) => (
                                <MenuItem component={Link} to={endpoint} key={menu} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{menu}</Typography>
                                </MenuItem>
                            ))}
                        </ProfileMenuStyled>
                    </Box>
                </Toolbar>
            </Container>
            {sessionStorage.setItem("header_loaded", JSON.stringify(true))}
        </StyledAppBar>
    );
};

export default Header;

