import React, { useState, useRef } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Container, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import BarChartIcon from '@mui/icons-material/BarChart';
import styled from 'styled-components';
import { style } from "@mui/system";

import VitalOverview from "./VitalHistory";

// Styled Components
const StyledListItem = styled(ListItem)`
  background-color: ${(props) => (props.isSelected ? '#e6d7ff' : '#b3e5e0')};  /* Highlight when selected */
  border: 0.5px solid #343a40;
  border-radius: 2%;
  color: black;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-bottom: 10px; /* Gap between tasks */
  
  &:hover {
    background-color: #e6d7ff;
    cursor: pointer;
  }
`;

const StyledContainer = styled(Container)`
  margin-top: 2vh;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 12px;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 16px;
  font-weight: 600;
`;

const CollapseContainer = styled(List)`
  color: #787878;
  padding-left: 20px;
`;


const Report = ({ patient }) => {
    // Individual section open states
    const [openReport, setOpenReport] = useState(false);

    // Refs for each section
    const reportRef = useRef(null);

    // Handle toggling the visibility of each section
    const handleSectionClick = (section) => {
        // Close all sections if clicked section is already open
        if (section === 'report' && openReport) {
            setOpenReport(false);
        } else {
            // Otherwise, open the clicked section
            setOpenReport(section === 'report');
        }

        // Scroll the clicked section into view when expanded
        if (section === 'report' && reportRef.current) {
            reportRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <StyledContainer maxwidthlg>
            <List disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>

                {/* Report Section */}
                <StyledListItem
                    button
                    onClick={() => handleSectionClick('report')}
                    isSelected={openReport}  // Highlight selected section
                >
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    {openReport ? <> <StyledListItemText primary="" />  </> : <StyledListItemText primary="Report" />}
                    {openReport ? <> <ExpandLess /> </> : <ExpandMore />}        </StyledListItem>
                <Collapse in={openReport} timeout="auto" unmountOnExit>
                    <CollapseContainer ref={reportRef}>
                        <VitalOverview patient={patient} />
                    </CollapseContainer>
                </Collapse>


                {/* Add list under Section */}
            </List>
        </StyledContainer>
    );
};

export default Report;

