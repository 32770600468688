import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Tooltip, IconButton, Button, ButtonGroup } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const BellCurve = ({ patient }) => {
  const { vitals } = patient; // Assuming vitals data is coming from the patient prop
  const { record_date, bloodPressure, temperature, heartRate, weight } = vitals;

  const [selectedVitals, setSelectedVitals] = useState({
    weight: true,
    heartRate: false,
    bloodPressure: false,
    temperature: false,
  });

  const [isChartVisible, setIsChartVisible] = useState(false);

  const toggleVital = (vital) => {
    setSelectedVitals((prevState) => ({
      ...prevState,
      [vital]: !prevState[vital],
    }));
  };

  const toggleChartVisibility = () => {
    setIsChartVisible((prevState) => !prevState);
  };

  const chartColors = {
    weight: '#FF7043', // Orange
    heartRate: '#64B5F6', // Blue
    bloodPressure: '#81C784', // Green
    temperature: '#FFD54F', // Yellow
  };

  // Function to extract vital data from the vitals array
  const extractVitalData = (vital) => vitals.map((vitalEntry) => {
    if (vital === 'weight') {
      return parseInt(vitalEntry.weight, 10);
    }
    if (vital === 'heartRate') {
      return parseInt(vitalEntry.heartRate, 10);
    }
    if (vital === 'bloodPressure') {
      return parseInt(vitalEntry.bloodPressure.split('/')[0], 10); // Use systolic pressure
    }
    if (vital === 'temperature') {
      return parseFloat(vitalEntry.temperature.replace('°F', ''));
    }
    return 0;
  });

  // Chart data based on selected vitals
  const bellCurveData = {
    labels: vitals.map((vital) => vital.record_date), // Use the record date as x-axis labels
    datasets: Object.keys(selectedVitals)
      .filter((key) => selectedVitals[key]) // Only include selected vitals
      .map((key) => {
        const vitalData = extractVitalData(key);
        const bellCurve = vitals; // Generate bell curve data for the vital
        return {
          label: `${key.charAt(0).toUpperCase() + key.slice(1)} Distribution`,
          data: bellCurve.map((point) => point.y), // Use the normalized y-values for the chart
          borderColor: chartColors[key],
          backgroundColor: chartColors[key] + '40', // 25% opacity
          fill: true,
        };
      }),
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Frequency',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} ${tooltipItem.dataset.label}`,
        },
      },
    },
  };

  return (
    <div>
      <Tooltip title={isChartVisible ? "Hide Distribution Chart" : "Show Distribution Chart"} arrow>
        <Button
          onClick={toggleChartVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isChartVisible ? <ExpandLess /> : <ExpandMore />} Vital Distribution Chart
        </Button>
      </Tooltip>

      {/* Vital Selection Buttons with Highlighting */}
      {isChartVisible && (
        <div style={{ marginBottom: '20px' }}>
          <ButtonGroup variant="outlined" color="primary">
            <Button
              onClick={() => toggleVital('heartRate')}
              style={{
                backgroundColor: selectedVitals.heartRate ? chartColors.heartRate : 'transparent',
                color: selectedVitals.heartRate ? 'white' : 'initial',
              }}
            >
              Heart Rate
            </Button>
            <Button
              onClick={() => toggleVital('bloodPressure')}
              style={{
                backgroundColor: selectedVitals.bloodPressure ? chartColors.bloodPressure : 'transparent',
                color: selectedVitals.bloodPressure ? 'white' : 'initial',
              }}
            >
              Blood Pressure
            </Button>
            <Button
              onClick={() => toggleVital('temperature')}
              style={{
                backgroundColor: selectedVitals.temperature ? chartColors.temperature : 'transparent',
                color: selectedVitals.temperature ? 'white' : 'initial',
              }}
            >
              Temperature
            </Button>
            <Button
              onClick={() => toggleVital('weight')}
              style={{
                backgroundColor: selectedVitals.weight ? chartColors.weight : 'transparent',
                color: selectedVitals.weight ? 'white' : 'initial',
              }}
            >
              Weight
            </Button>
          </ButtonGroup>
        </div>
      )}

      {/* Chart */}
      {isChartVisible && (
        <div style={{ marginTop: '30px' }}>
          <h6>Distribution Chart</h6>
          <Line data={bellCurveData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default BellCurve;
