import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Container, Box, Typography } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import './App.css';

// Import components
import Header from './components/Common/Header';
import Login from './components/Common/Login';
import Logout from './components/Common/Logout';
import Home from './components/Home';
import NotFoundPage from './components/Common/404';
import Report from './components/Report';

// Import other pages
import Case from './components/Case';
import CreateCase from './components/Case/Create';
import Clerk from './components/Clerk';
import CreateClerk from './components/Clerk/Create';
import Doctor from './components/Doctor';
import CreateDoctor from './components/Doctor/Create';
import Patient from './components/Patient';
import CreatePatient from './components/Patient/Create';
import Vaccine from './components/Vaccine';
import Prescription from './components/Prescription';
import LaboratoryRecord from './components/Laboratory';
import Medicine from './components/Medicine';
import CreateMedicine from './components/Medicine/Create';
import Consultation from './components/Consultation';
import Allergy from './components/Allergy';
import Appointment from './components/Appointment';
import Billing from './components/Billing';
import Resource from './components/Resource';
import Account from './components/Account';
import Contact from './components/Contact';

import BellCurve from './components/Report/BellCurve';
import RegressionChart from './components/Report/RegressionChart';
import SPCChart from './components/Report/SPCChart'
import TrendChart from './components/Report/TrendChart';
import VisitStat from './components/Report/VisitStat'
import VitalHistory from './components/Report/VitalHistory';


// Context Provider
import { DoctorContextProvider } from './components/Doctor/Context';

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  // Show loading state
  if (isLoading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <Box>
          <Typography variant="h4" color="primary">
            Initializing ...
          </Typography>
        </Box>
      </Container>
    );
  }

  // Show login page if not authenticated
  if (!isAuthenticated) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <Box>
          <Typography variant="h4" color="secondary">
            Login to continue...
          </Typography>
          <Login />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <DoctorContextProvider>
        <Router>
          <CssBaseline />
          {/* Header */}
          <Header />

          {/* Main Content */}
          <Container sx={{ mt: 4, minHeight: '80vh' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/account" element={<Account />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/resource" element={<Resource />} />
              <Route path="/report" element={<Report />} />
              <Route path="*" element={<NotFoundPage />} />

              {/* Other routes */}
              <Route path="/allergy" element={<Allergy />} />
              <Route path="/appointment" element={<Appointment />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/case" element={<Case />} />
              <Route path="/clerk" element={<Clerk />} />
              <Route path="/doctor" element={<Doctor />} />
              <Route path="/patient" element={<Patient />} />
              <Route path="/consultation" element={<Consultation />} />
              <Route path="/laboratory_record" element={<LaboratoryRecord />} />
              <Route path="/prescription" element={<Prescription />} />
              <Route path="/medicine" element={<Medicine />} />
              <Route path="/vaccine" element={<Vaccine />} />
              
              {/*Report routes*/}
              <Route path="/report_bell_curve" element={<BellCurve />} />
              <Route path="/report_regression_chart" element={<RegressionChart />} />
              <Route path="/report_spc" element={<SPCChart />} />
              <Route path="/report_trend_chart" element={<TrendChart />} />
              <Route path="/report_visit_stat" element={<VisitStat />} />
              <Route path="/report_vital_history" element={<VitalHistory />} />

              {/* Create routes */}
              <Route path="/case/create" element={<CreateCase />} />
              <Route path="/clerk/create" element={<CreateClerk />} />
              <Route path="/doctor/create" element={<CreateDoctor />} />
              <Route path="/medicine/create" element={<CreateMedicine />} />
              <Route path="/patient/create" element={<CreatePatient />} />
            </Routes>
          </Container>
        </Router>
      </DoctorContextProvider>
    </>
  );
}

export default App;
