import React, { useState } from "react";
import styled from "styled-components";
import SwiperCore from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PatientCard from "./PatientCard";
import GPT3Response from '../Common/AI';
import Consultation from '../Consultation';
import Report from '../Report';
import PatientVisitsTable from '../Report/VisitStat';
import AdmitDischargeForm from "./AdmitDischargeForm";

SwiperCore.use([Keyboard, Mousewheel, Pagination, Navigation]);

// Styled Components
const DashboardWrapper = styled.div`
  display: flex;
  background-color: #f5f5f5;
  padding: 20px;
`;

const PatientListWrapper = styled.div`
  width: 35%;
  padding-right: 20px;
`;

const PatientInfoWrapper = styled.div`
  padding: 10px;
  width: 65%;
`;

const PatientImageWrapper = styled.div`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PatientInfoText = styled.div`
  color: black;
  margin-left: 20px;
`;

const PatientDetails = styled.div`
  font-size: 12px;
  margin-left: 20px;
`;

const TableContainer = styled.div`
  margin-top: 5vh;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding: 20px;
  background-color: #f8f8f8;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const LoadingWrapper = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #007bff;
  font-weight: bold;
`;

export default function PatientListDashboard({ patients }) {
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [enableChatgpt] = useState(false);

    const handlePatientSelect = (patientId) => {
        setSelectedPatientId(patientId);
    };

    const selectedPatient = patients.find((patient) => patient.id === selectedPatientId);

    return (
        <DashboardWrapper>
            <PatientListWrapper>
                <Swiper
                    slidesPerView="1"
                    effect={true}
                    loop={false}
                    mousewheel={true}
                    keyboard={{ enabled: true, onlyInViewport: false }}
                    direction="horizontal"
                    pagination={{ clickable: true, type: 'progressbar' }}
                    height={window.innerHeight}
                    spaceBetween={150}
                    simulateTouch={true}
                >
                    {patients.map((patient) => (
                        <SwiperSlide key={patient.id}>
                            <PatientCard
                                patient={patient}
                                handlePatientSelect={handlePatientSelect}
                                isSelected={selectedPatientId === patient.id}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </PatientListWrapper>

            {selectedPatient ? (
                <PatientInfoWrapper>
                    <PatientImageWrapper>
                        <img className="patient-card-image" src={selectedPatient.photo} alt={selectedPatient.name} />
                    </PatientImageWrapper>
                    <PatientInfoText>
                        <h5>{selectedPatient.name}</h5>
                    </PatientInfoText>
                    <PatientDetails>
                        Date of Birth: {selectedPatient.dob}
                    </PatientDetails>
                    <PatientDetails>
                        Phone: {selectedPatient.phone}
                    </PatientDetails>

                    {enableChatgpt && (
                        <GPT3Response
                            query={`Based on the following vital information, blood pressure: ${selectedPatient.vitals.bloodPressure}, body temperature: ${selectedPatient.vitals.temperature}, heart rate: ${selectedPatient.vitals.heartRate}, and date of birth: ${selectedPatient.dob}, give a clinical support summary of overall health and inference summary. Suggest normal ranges and how it compares with the provided information. Make it concise.`}
                        />
                    )}

                    <TableContainer>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <TableHead>Last Visit</TableHead>
                                    <TableHead>Reason</TableHead>
                                    <TableHead>Summary</TableHead>
                                    <TableHead>Prescription</TableHead>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPatient.visits.map((visit) => (
                                    <tr key={visit.date}>
                                        <TableCell>{visit.date}</TableCell>
                                        <TableCell>{visit.reason}</TableCell>
                                        <TableCell>{visit.summary}</TableCell>
                                        <TableCell>{visit.prescription}</TableCell>
                                    </tr>
                                ))}
                            </tbody>
                        </StyledTable>
                    </TableContainer>

                    <Consultation patient={selectedPatient} />
                    <Report patient={selectedPatient}/>
                    <AdmitDischargeForm patient={selectedPatient} />
                </PatientInfoWrapper>
            ) : (
                <LoadingWrapper>
                    <LoadingWrapper>
                        <ol className="instructions-list">
                            <li>Select a patient or </li>
                            <li>Customize dashboard preference in the menu.</li>
                            Navigate through the appointment list by swiping left or right or by using the Keyboard arrows or Mouse wheel. 
                        </ol>
                    </LoadingWrapper>
                </LoadingWrapper>
            )}
        </DashboardWrapper>
    );
}
