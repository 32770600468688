import axios from 'axios';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

const token = process.env.REACT_APP_API_KEY;

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://boafo.com/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
});

// Global variable to store error messages
let setGlobalError;

// Error display component
const ErrorDisplay = () => {
  const [error, setError] = useState('');
  setGlobalError = setError;

  if (!error) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'red',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {error}
      <button
        onClick={() => setError('')}
        style={{
          backgroundColor: 'white',
          color: 'red',
          border: 'none',
          padding: '5px',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Close
      </button>
    </div>
  );
};

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    console.log('Request Config:', config);
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    console.log('Response:', response);
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      console.error('HTTP Status Code:', status);
      console.error('Error Response Data:', error.response.data);

      // Error messages based on HTTP status codes
      switch (status) {
        case 400:
          setGlobalError?.('Error: Check your request data for issues.');
          break;
        case 401:
          setGlobalError?.('Error: Your API key or token is incorrect.');
          break;
        case 403:
          setGlobalError?.('Error: You do not have permission to access this resource.');
          break;
        case 404:
          setGlobalError?.('Error: The requested resource was not found. Please check the URL.');
          break;
        case 500:
          setGlobalError?.('Error: The server encountered an issue. Please try again later.');
          break;
        case 503:
          setGlobalError?.('Error: The service is unavailable. Please try again later.');
          break;
        default:
          setGlobalError?.('Error: Connecting to the backend.');
      }
    } else {
      console.error('Error Message:', error.message);
      setGlobalError?.('Error: Unable to connect to the server. Please check your network connection.');
    }
    return Promise.reject(error);
  }
);

export default instance;

// Render the error display component
const root = document.createElement('div');
document.body.appendChild(root);

// Use createRoot for React 18
const reactRoot = createRoot(root);
reactRoot.render(<ErrorDisplay />);

