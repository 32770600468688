import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Context } from '../Doctor/Context';
import VisitReport from './VisitReport';

// Styled Components
const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const LoadingContainer = styled.div`
  margin-top: 2vh;
  padding: 6px;
  text-align: center;
`;

const Message = styled.p`
  font-size: 18px;
  color: #007bff;
  font-weight: bold;
`;

export default function ListComponent() {
    const [showDesktop, setShowDesktop] = useState(true);
    const profile = useContext(Context);

    return (
        <ContainerWrapper>
            {showDesktop ? (
                <div>
                    <VisitReport />
                </div>
            ) : (
                <LoadingContainer>
                    <Message>Loading...</Message>
                </LoadingContainer>
            )}
        </ContainerWrapper>
    );
}

