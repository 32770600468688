import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h3`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const LabConsultation = ({ patient }) => {
  const { lab } = patient;
  const { lab_name, lab_result, unit, reference_range, note } = lab;
  const [labObject, setLabObject] = useState([]);
  const [newLab, setNewLabData] = useState({
    patientName: "",
    testName: "",
    testResult: "",
    units: "",
    referenceRange: "",
    notes: "",
  });

  const handleChange = (event) => {
    setNewLabData({
      ...newLab,
      [event.target.name]: event.target.value,
    });
  };

  const addLabTest = (event) => {
    event.preventDefault();
    setLabObject([...labObject, newLab]);
    setNewLabData({
      patientName: "",
      testName: "",
      testResult: "",
      units: "",
      referenceRange: "",
      notes: "",
    });
  };

  return (
    <FormWrapper>
      <FormTitle>{patient.name}'s lab</FormTitle>
      <form onSubmit={addLabTest}>
        <InputField
          type="text"
          placeholder="Test Name"
          id="testName"
          name="testName"
          value={newLab.testName}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Test Result"
          id="testResult"
          name="testResult"
          value={newLab.testResult}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Units"
          id="units"
          name="units"
          value={newLab.units}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Reference Range"
          id="referenceRange"
          name="referenceRange"
          value={newLab.referenceRange}
          onChange={handleChange}
        />
        <TextareaField
          placeholder="Notes"
          id="notes"
          name="notes"
          value={newLab.notes}
          onChange={handleChange}
        />
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHead>Test Name</TableHead>
              <TableHead>Result</TableHead>
              <TableHead>Unit</TableHead>
              <TableHead>Date Administered</TableHead>
              <TableHead>Reference Range</TableHead>
              <TableHead>Note</TableHead>
            </tr>
          </thead>
          <tbody>
            {lab.map((labData, index) => (
              <tr key={index}>
                <TableCell>{labData.lab_name}</TableCell>
                <TableCell>{labData.lab_result}</TableCell>
                <TableCell>{labData.unit}</TableCell>
                <TableCell>{labData.date}</TableCell>
                <TableCell>{labData.reference_range}</TableCell>
                <TableCell>{labData.note}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </FormWrapper>
  );
};

export default LabConsultation;
