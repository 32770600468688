import React, { useState, useContext } from "react";
import { Context } from '../Doctor/Context'
import styled from 'styled-components';

// Styled components for modern look
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;  /* Max width for larger screens */
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h3`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dictation = ({ patient }) => {
  const [visitData, setVisitReport] = useState([]);
  const { id, visits, vitals, appointment } = patient;
  const { bloodPressure, temperature, heartRate, weight } = vitals;
  const { date, type, status, reason, stage } = appointment;
  let doctor_context = useContext(Context)

  const [newVisitReport, setNewVisitData] = useState({
    dateOfVisit: date,
    reasonForVisit: reason,
    doctor: doctor_context._id,
    observation: "",
    outcome: "",
    followUpInstructions: "",
  });

  const handleChange = (event) => {
    setNewVisitData({
      ...newVisitReport,
      [event.target.name]: event.target.value,
    });
  };

  const addVisitReport = (event) => {
    event.preventDefault();
    setVisitReport([...visitData, newVisitReport]);
    setNewVisitData({
      dateOfVisit: date,
      reasonForVisit: reason,
      doctor: doctor_context._id,
      observation: "",
      outcome: "",
      followUpInstructions: "",
    });
  };

  return (
    <FormWrapper>
      <FormTitle>{patient.name}'s dictation</FormTitle>
      <form onSubmit={addVisitReport}>
        <div>
          <InputField
            type="text"
            placeholder="Observation"
            id="observation"
            name="observation"
            value={newVisitReport.observation}
            onChange={handleChange}
          />
        </div>
        <div>
          <InputField
            type="text"
            placeholder="Outcome"
            id="outcome"
            name="outcome"
            value={newVisitReport.outcome}
            onChange={handleChange}
          />
        </div>
        <div>
          <TextareaField
            placeholder="Auto dictation"
            id="followUpInstructions"
            name="followUpInstructions"
            value={newVisitReport.followUpInstructions}
            onChange={handleChange}
          />
        </div>
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>
    </FormWrapper>
  );
};

export default Dictation;

