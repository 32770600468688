import React, { useState } from 'react';
import List from "@mui/material/List";
import Container from '@mui/material/Container';
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import DomainAddIcon from '@mui/icons-material/DomainAdd';

function AdmitDischargeForm({ patient }) {
    const { name } = patient
    const [openAdmission, setOpenAdmission] = useState(false);
    const [admitted, setAdmitted] = useState(false);
    const [discharged, setDischarge] = useState(false);
    const [admissionTimestamp, setAdmissionTimestamp] = useState('');
    const [dischargeTimestamp, setDischargeTimestamp] = useState('');

    const handleAdmitPatient = () => {
        const timestamp = new Date().toLocaleString();
        setAdmitted(true);
        setDischarge(false)
        setAdmissionTimestamp(timestamp);
    };

    const handleDischargePatient = () => {
        const timestamp = new Date().toLocaleString();
        setDischarge(true)
        setAdmitted(false);
        setDischargeTimestamp(timestamp);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAdmitPatient();
    };

    const handleAdmissionClick = () => {
        setOpenAdmission(!openAdmission);
    };

    // TODO: need some logic
    return (
        <>
            <Container maxwidthlg='true' style={{
                marginTop: '2vh',
                boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                padding: '10px',
                backgroundColor: '#F8F8F8'
            }}>
                <List
                    disablePadding
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem button dense onClick={handleAdmissionClick}
                        sx={{
                            bgcolor: '#f7eada',
                            border: '0.5px solid #343a40',
                            borderRadius: '2%',
                            color: 'black',
                            fontWeight: '600'
                        }}>
                        <ListItemIcon sx={{ color: '#42c98a' }}>
                            <DomainAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="In-Patient" />
                        {/*code to openAdmission and closed list*/}
                        {openAdmission ? <> <ListItemText secondary={name} />  <ExpandLess /></> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAdmission} timeout="auto" unmountOnExit>
                        {/*List item are wrapped inside List */}
                        <List component="div" disablePadding
                            style={{
                                color: '#787878',
                            }}>
                            <div style={{ marginTop: '20px' }}>
                                {admitted ? (
                                    <div style={{ color: "#cacfcc", fontWeight: "bold" }}>
                                        <form>
                                            <button onClick={handleDischargePatient}>Discharge patient</button>
                                        </form>
                                        <h6>{name} has been admitted on {admissionTimestamp}.</h6>
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <button type="submit">Admit patient</button>
                                    </form>
                                )}
                                {discharged ? (
                                    <div style={{ color: "#cacfcc", fontWeight: "bold" }}>
                                        <h6>{name} has been discharged as of {dischargeTimestamp}.</h6>
                                    </div>
                                ) : (
                                    ' '
                                )}
                            </div>
                        </List>

                    </Collapse>
                </List>
            </Container>
        </>

    );
}

export default AdmitDischargeForm;
