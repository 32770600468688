import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { IconButton, Tooltip, Button } from '@mui/material';
import { FitnessCenter, HeartBroken, TrendingUp, Thermostat, ExpandMore, ExpandLess, BarChart } from '@mui/icons-material';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const VitalsHistory = ({ patient }) => {
  const { vitals } = patient;
  const { record_date, bloodPressure, temperature, heartRate, weight } = vitals;


  const [selectedVitals, setSelectedVitals] = useState({
    weight: true,
    heartRate: false,
    bloodPressure: false,
    temperature: false,
  });

  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isChartVisible, setIsChartVisible] = useState(true);

  const toggleVital = (vital) => {
    setSelectedVitals((prevState) => ({
      ...prevState,
      [vital]: !prevState[vital],
    }));
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible((prevState) => !prevState);
  };

  const toggleChartVisibility = () => {
    setIsChartVisible((prevState) => !prevState);
  };

  const getVitalData = (vital) => vitals.map((vitalEntry) => {
    if (vital === 'weight') {
      return parseInt(vitalEntry.weight, 10);
    }
    if (vital === 'heartRate') {
      return parseInt(vitalEntry.heartRate, 10);
    }
    if (vital === 'bloodPressure') {
      return parseInt(vitalEntry.bloodPressure.split('/')[0], 10);
    }
    if (vital === 'temperature') {
      return parseFloat(vitalEntry.temperature.replace('°F', ''));
    }
    return 0;
  });

  const chartColors = {
    weight: '#FF7043', // Orange
    heartRate: '#64B5F6', // Blue
    bloodPressure: '#81C784', // Green
    temperature: '#FFD54F', // Yellow
  };

  const chartData = {
    labels: vitals.map((vital) => vital.record_date),
    datasets: Object.keys(selectedVitals)
      .filter((key) => selectedVitals[key])
      .map((key) => ({
        label: `${key.charAt(0).toUpperCase() + key.slice(1)} History`,
        data: getVitalData(key),
        borderColor: chartColors[key],
        backgroundColor: chartColors[key] + '40', // 25% opacity
        fill: true,
      })),
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Units',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} ${tooltipItem.dataset.label}`,
        },
      },
    },
  };

  return (
    <div>
      {/* <h2>Vital History</h2> */}

      {/* Button to Expand or Collapse the History */}
      <Button
        onClick={toggleHistoryVisibility}
        variant="contained"
        color="primary"
        startIcon={isHistoryVisible ? <ExpandLess /> : <ExpandMore />}
        style={{ marginBottom: '20px' }}
      >
        {isHistoryVisible ? 'Hide History' : 'Show History'}
      </Button>

      {/* History Table */}
      {isHistoryVisible && (
        <table border="1" style={{ marginBottom: '20px' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Blood Pressure</th>
              <th>Temperature</th>
              <th>Heart Rate</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            {vitals.map((vital, index) => (
              <tr key={index}>
                <td>{vital.record_date}</td>
                <td>{vital.bloodPressure}</td>
                <td>{vital.temperature}</td>
                <td>{vital.heartRate}</td>
                <td>{vital.weight}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Chart Toggle Icon */}
      <Tooltip title={isChartVisible ? "Hide Chart" : "Show Chart"} arrow>
        <IconButton
          onClick={toggleChartVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isChartVisible ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Tooltip>

      {/* Chart */}
      {isChartVisible && (
        <div style={{ marginTop: '30px' }}>
          {/* <h3>Vital History Chart</h3> */}
          <Line data={chartData} options={chartOptions} />
        </div>
      )}

      {/* Modern Toggle Buttons with Material UI Icons */}
      <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Tooltip title="Toggle Weight" arrow>
          <IconButton
            color={selectedVitals.weight ? 'primary' : 'default'}
            onClick={() => toggleVital('weight')}
            style={{ color: chartColors.weight }}
          >
            <FitnessCenter />
          </IconButton>
        </Tooltip>

        <Tooltip title="Toggle Heart Rate" arrow>
          <IconButton
            color={selectedVitals.heartRate ? 'primary' : 'default'}
            onClick={() => toggleVital('heartRate')}
            style={{ color: chartColors.heartRate }}
          >
            <HeartBroken />
          </IconButton>
        </Tooltip>

        <Tooltip title="Toggle Blood Pressure" arrow>
          <IconButton
            color={selectedVitals.bloodPressure ? 'primary' : 'default'}
            onClick={() => toggleVital('bloodPressure')}
            style={{ color: chartColors.bloodPressure }}
          >
            <TrendingUp />
          </IconButton>
        </Tooltip>

        <Tooltip title="Toggle Temperature" arrow>
          <IconButton
            color={selectedVitals.temperature ? 'primary' : 'default'}
            onClick={() => toggleVital('temperature')}
            style={{ color: chartColors.temperature }}
          >
            <Thermostat />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default VitalsHistory;

