import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAuth0 } from "@auth0/auth0-react";

const NotFound = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return (
            <>
                <CssBaseline />
                <Container maxWidth="lg" style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '3rem 1rem',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#007bff',
                    }}>
                        <Typography variant="h4">Loading...</Typography>
                    </Box>
                </Container>
            </>
        );
    }

    return (
        isAuthenticated && (
            <>
                <CssBaseline />
                <Container maxWidth="lg" style={{
                    marginTop: '2vh',
                    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                    padding: '3rem 1rem',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#343a40',
                    }}>
                        <Typography variant="h1" style={{ fontSize: '4rem', fontWeight: 'bold', color: '#dc3545' }}>
                            404
                        </Typography>
                        <Typography variant="h5" style={{
                            marginTop: '1rem',
                            color: '#F58634',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                        }}>
                            The resource is not available to you at this time.
                        </Typography>
                    </Box>
                </Container>
            </>
        )
    );
};

export default NotFound;
