import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/ionicons/heart';
import { thermometer } from 'react-icons-kit/ionicons/thermometer';
import { iosPulse } from 'react-icons-kit/ionicons/iosPulse';
import { plusSquare } from 'react-icons-kit/fa/plusSquare';
import { windowClose } from 'react-icons-kit/fa/windowClose';
import { scale } from 'react-icons-kit/metrize/scale';
import { clock } from 'react-icons-kit/oct/clock';
import { ic_room } from 'react-icons-kit/md/ic_room';
import { ic_medical_services } from 'react-icons-kit/md/ic_medical_services';
import { calendar } from 'react-icons-kit/fa/calendar';
import { iosMedicalOutline } from 'react-icons-kit/ionicons/iosMedicalOutline';
import { ExpandMore, ExpandLess, ArrowForward } from '@mui/icons-material';
import styled from 'styled-components';

// Styled Components for Modern UI
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;
  /* Highlight selected card */
  border: ${({ isSelected }) => (isSelected ? '1px solid #007bff' : 'none')};
  background-color: ${({ isSelected }) => (isSelected ? '#f0f8ff' : '#fff')};
  padding: 20px;
`;

const CardHeader = styled.div`
  display: flex;
  padding: 16px;
  background-color: #f4f6f9;
  border-bottom: 1px solid #e1e4e8;
`;

const CardImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 16px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #333;
`;

const StageLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ stage }) => (stage === 'consultation' ? '#007bff' : '#AA0144')};
  display: flex;
  align-items: center;
`;

const ExpandableContent = styled.div`
  padding: 16px;
  background-color: #fafafa;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : '0')};
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin: 8px 0;
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #e1e4e8;
  &:last-child {
    border-bottom: none;
  }
`;

const TableData = styled.td`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
`;

const ActionButton = styled(Icon)`
  padding: 8px 16px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #e1e4e8;
  }
`;

const AppointmentStatistics = styled.div`
  display: flex;
  gap: 10px; /* No space between Consultations and Requests */
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  font-size: 12px; /* Smaller font size */
`;

const PatientCard = ({ patient, isSelected, handlePatientSelect, allPatients = [] }) => {
  const { id, name, photo, vitals, appointment } = patient;
  const { record_date, bloodPressure, temperature, heartRate, weight } = vitals[0];
  const { date, type, doctor, status, reason, stage } = appointment;

  const [expanded, setExpanded] = useState(true);

  const handleAppointmentAction = (action) => {
    console.log(`Appointment action: ${action}`);
  };

  const handleClick = () => {
    if (handlePatientSelect) {
      handlePatientSelect(id);
    }
  };

  // Calculate the number of patients for each appointment type
  const getAppointmentStats = (patients) => {
    const stats = {
      consultation: 5,
      request: 4,
    };

    // Only process if the patients array is valid and not empty
    if (Array.isArray(patients)) {
      patients.forEach(patient => {
        if (patient.appointment && patient.appointment.type) {
          stats[patient.appointment.type] += 1;
        }
      });
    }

    return stats;
  };

  const appointmentStats = getAppointmentStats(allPatients);

  return (
    <div>
      {/* Appointment statistics */}
      <AppointmentStatistics>
        <p>Consultations: {appointmentStats.consultation}</p>
        <p>Requests: {appointmentStats.request}</p>
      </AppointmentStatistics>

      {/* Patient Card */}
      <Card isSelected={isSelected} onClick={handleClick}>
        <CardHeader>
          <CardImage src={photo} />
          <CardTitle>
            <Name>{name}</Name>
            <StageLabel stage={stage}>
              <ArrowForward style={{ marginRight: '8px', color: '#007bff' }} />
              {stage === 'consultation' ? 'Consultation' : 'Request'}
            </StageLabel>
          </CardTitle>
        </CardHeader>

        <ExpandableContent isExpanded={expanded}>
          {stage === 'consultation' ? (
            <div>
              <Table>
                <tbody>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#f5427b', paddingRight: '8px' }} size={20} icon={ic_medical_services} />
                      {reason}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#87b0c9', paddingRight: '8px' }} size={20} icon={iosPulse} />
                      {bloodPressure}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#a1cfca', paddingRight: '8px' }} size={20} icon={thermometer} />
                      {temperature}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#fadfc5', paddingRight: '8px' }} size={20} icon={heart} />
                      {heartRate}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#243dab', paddingRight: '8px' }} size={20} icon={scale} />
                      {weight}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#53b03c', paddingRight: '8px' }} size={20} icon={ic_room} />
                      Room #2
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#3c61b0', paddingRight: '8px' }} size={20} icon={clock} />
                      15m
                    </TableData>
                  </TableRow>
                </tbody>
              </Table>
            </div>
          ) : (
            <div>
              <div style={{ textAlign: 'center' }}>
                <ActionButton onClick={() => handleAppointmentAction('Reject')} style={{ color: '#f7816f' }} size={20} icon={windowClose} />
                <ActionButton onClick={() => handleAppointmentAction('Accept')} style={{ color: '#87c989' }} size={20} icon={plusSquare} />
              </div>
              <Table>
                <tbody>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#fac5da', paddingRight: '8px' }} size={20} icon={calendar} />
                      {date}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#3cb051', paddingRight: '8px' }} size={20} icon={iosMedicalOutline} />
                      {type}
                    </TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      <Icon style={{ color: '#f5427b', paddingRight: '8px' }} size={20} icon={ic_medical_services} />
                      {reason}
                    </TableData>
                  </TableRow>
                </tbody>
              </Table>
            </div>
          )}
        </ExpandableContent>

        {/* Expand/Collapse Button */}
        <div onClick={() => setExpanded(!expanded)} style={{ padding: '8px', textAlign: 'center', cursor: 'pointer' }}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </Card>
    </div>
  );
};

export default PatientCard;

