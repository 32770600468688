import React, { useState } from 'react';
import Container from '@mui/material/Container'

const ClinicalVisitsAnalytics = () => {
  const [data] = useState([{ month: 'Jan', visits: 100, details: [{ diagnosis: 'Flu', visitDetails: 'Patient had flu symptoms and was given medication' }, { diagnosis: 'Cold', visitDetails: 'Patient had cold symptoms and was given a prescription' }] },
  { month: 'Feb', visits: 90, details: [{ diagnosis: 'Headache', visitDetails: 'Patient had headache and was given pain relief medication' }, { diagnosis: 'Back pain', visitDetails: 'Patient had back pain and was referred to a specialist' }] },
  { month: 'Mar', visits: 95, details: [{ diagnosis: 'Stomach ache', visitDetails: 'Patient had stomach ache and was given antacids' }, { diagnosis: 'Allergy', visitDetails: 'Patient was diagnosed with allergies and was given an allergy shot' }] },
  ]);

  return (
    <Container maxwidthlg='true' style={{
      marginTop: '2vh',
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
      padding: '10px',
      backgroundColor: '#F8F8F8'
    }}>
    <table>
      <thead>
        <tr>
          <th>Month</th>
          <th>Clinical Visits</th>
          <th>Outcome</th>
          <th>Summary</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ month, visits, details }) => (
          <>
            {details.map(({ diagnosis, visitDetails }) => (
              <tr key={diagnosis}>
                <td>{month}</td>
                <td>{visits}</td>
                <td>{diagnosis}</td>
                <td>{visitDetails}</td>
              </tr>
            ))}
          </>
        ))}
      </tbody>
    </table>
    </Container>
  );
};

export default ClinicalVisitsAnalytics;

