// RegressionChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

const RegressionChart = ({ patient, label }) => {
    const { vitals } = patient;
  const chartData = {
    labels: vitals.timestamps,
    datasets: [{
      label: `${label} Values`,
      data: vitals.values,
      borderColor: 'rgba(75, 192, 192, 1)',
      fill: false,
    }, {
      label: 'Regression Line',
      data: vitals.regressionLine,
      borderColor: 'rgba(153, 102, 255, 1)',
      fill: false,
      borderDash: [5, 5],
    }]
  };

  return (
    <div className="regression-chart">
      <h4>{label} Regression</h4>
      <Line data={chartData} />
    </div>
  );
};

export default RegressionChart;
