import React, { useState } from "react";
import styled from 'styled-components';

// Styled Components
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h3`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const AllergyConsultation = ({ patient }) => {
  const { allergy } = patient;
  const [allergyObject, setAllergyObject] = useState([]);
  const [newAllergy, setNewAllergyData] = useState({
    patientName: "",
    allergen: "",
    reaction: "",
    severity: "",
    notes: "",
  });

  const handleChange = (event) => {
    setNewAllergyData({
      ...newAllergy,
      [event.target.name]: event.target.value,
    });
  };

  const addAllergyTest = (event) => {
    event.preventDefault();
    setAllergyObject([...allergyObject, newAllergy]);
    setNewAllergyData({
      patientName: "",
      allergen: "",
      reaction: "",
      severity: "",
      notes: "",
    });
  };

  return (
    <FormWrapper>
      <FormTitle>{patient.name}'s allergy </FormTitle>
      <form onSubmit={addAllergyTest}>
        <InputField
          type="text"
          placeholder="Allergen"
          id="allergen"
          name="allergen"
          value={newAllergy.allergen}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Reaction"
          id="reaction"
          name="reaction"
          value={newAllergy.reaction}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Severity"
          id="severity"
          name="severity"
          value={newAllergy.severity}
          onChange={handleChange}
        />
        <TextareaField
          placeholder="Notes"
          id="notes"
          name="notes"
          value={newAllergy.notes}
          onChange={handleChange}
        />
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHead>Allergen</TableHead>
              <TableHead>Reaction</TableHead>
              <TableHead>Severity</TableHead>
              <TableHead>Record Date</TableHead>
              <TableHead>Summary</TableHead>
            </tr>
          </thead>
          <tbody>
            {allergy.map((allergyData, index) => (
              <tr key={index}>
                <TableCell>{allergyData.name}</TableCell>
                <TableCell>{allergyData.reaction}</TableCell>
                <TableCell>{allergyData.severity}</TableCell>
                <TableCell>{allergyData.record_date}</TableCell>
                <TableCell>{allergyData.note}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </FormWrapper>
  );
};

export default AllergyConsultation;
