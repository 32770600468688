import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { IconButton, Tooltip, Button, ButtonGroup } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const RegressionChart = ({ patient }) => {
  const { vitals } = patient;

  // States for chart visibility and selected vital
  const [isChartVisible, setIsChartVisible] = useState(false);
  const [selectedVital, setSelectedVital] = useState('weight'); // Default to 'weight'

  // Toggle chart visibility
  const toggleChartVisibility = () => {
    setIsChartVisible((prevState) => !prevState);
  };

  // Handle selection of different vitals
  const handleVitalSelection = (vital) => {
    setSelectedVital(vital);
  };

  // Get the data for the selected vital
  const getVitalData = (vital) => vitals.map((vitalEntry) => {
    if (vital === 'weight') {
      return parseInt(vitalEntry.weight, 10);
    }
    if (vital === 'heartRate') {
      return parseInt(vitalEntry.heartRate, 10);
    }
    if (vital === 'bloodPressure') {
      return parseInt(vitalEntry.bloodPressure.split('/')[0], 10); // Assuming systolic
    }
    if (vital === 'temperature') {
      return parseFloat(vitalEntry.temperature.replace('°F', ''));
    }
    return 0;
  });

  const chartColors = {
    weight: '#FF7043', // Orange
    heartRate: '#64B5F6', // Blue
    bloodPressure: '#81C784', // Green
    temperature: '#FFD54F', // Yellow
  };

  const chartData = {
    labels: vitals.map((vital) => vital.record_date), // Assuming `record_date` is available for x-axis
    datasets: [
      {
        label: `${selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1)} Values`, // Dynamic label based on selection
        data: getVitalData(selectedVital),
        borderColor: chartColors[selectedVital],
        backgroundColor: chartColors[selectedVital] + '40', // 25% opacity
        fill: true,
      },
      {
        label: 'Regression Line', // Assuming the regression line is available as a dataset
        data: vitals.map((vital) => vital.regressionLine || 0), // Assuming `regressionLine` exists for vitals
        borderColor: 'rgba(153, 102, 255, 1)', // Purple line for regression
        fill: false,
        borderDash: [5, 5], // Dotted line
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1), // Dynamic y-axis label
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            const label = tooltipItem.dataset.label;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div>
      {/* Chart Visibility Toggle */}
      <Tooltip title={isChartVisible ? "Hide Regression Chart" : "Show Regression Chart"} arrow>
        <Button
          onClick={toggleChartVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isChartVisible ? <ExpandLess /> : <ExpandMore />} Vital Regression Chart
        </Button>
      </Tooltip>

      {/* Vital Selection Buttons with Highlighting */}
      {isChartVisible && (
        <div style={{ marginBottom: '20px' }}>
          <ButtonGroup variant="outlined" color="primary">

            <Button
              onClick={() => handleVitalSelection('heartRate')}
              style={{
                backgroundColor: selectedVital === 'heartRate' ? chartColors.heartRate : 'transparent',
                color: selectedVital === 'heartRate' ? 'white' : 'initial',
              }}
            >
              Heart Rate
            </Button>
            <Button
              onClick={() => handleVitalSelection('bloodPressure')}
              style={{
                backgroundColor: selectedVital === 'bloodPressure' ? chartColors.bloodPressure : 'transparent',
                color: selectedVital === 'bloodPressure' ? 'white' : 'initial',
              }}
            >
              Blood Pressure
            </Button>
            <Button
              onClick={() => handleVitalSelection('temperature')}
              style={{
                backgroundColor: selectedVital === 'temperature' ? chartColors.temperature : 'transparent',
                color: selectedVital === 'temperature' ? 'white' : 'initial',
              }}
            >
              Temperature
            </Button>
            <Button
              onClick={() => handleVitalSelection('weight')}
              style={{
                backgroundColor: selectedVital === 'weight' ? chartColors.weight : 'transparent',
                color: selectedVital === 'weight' ? 'white' : 'initial',
              }}
            >
              Weight
            </Button>
          </ButtonGroup>
        </div>
      )}
      {/* Chart */}
      {isChartVisible && (
        <div style={{ marginTop: '30px' }}>
          <h6>{selectedVital.charAt(0).toUpperCase() + selectedVital.slice(1)} Regression Chart</h6>
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default RegressionChart;


