// TrendChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

const TrendChart = ({ patient, label }) => {
    const { vitals } = patient;
  const chartData = {
    labels: vitals.timestamps,
    datasets: [{
      label: label,
      data: vitals.values,
      borderColor: 'rgba(54, 162, 235, 1)',
      fill: false,
    }]
  };

  return (
    <div className="trend-chart">
      <h4>{label} Trend</h4>
      <Line data={chartData} />
    </div>
  );
};

export default TrendChart;
