import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h3`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const PrescriptionManager = ({ patient }) => {
  const { prescription } = patient;
  const [prescriptionObject, setPrescriptionObject] = useState([]);
  const [newPrescription, setNewPrescription] = useState({
    patientName: "",
    drugName: "",
    dosage: "",
    frequency: "",
    duration: "",
    notes: "",
  });

  const handleChange = (event) => {
    setNewPrescription({
      ...newPrescription,
      [event.target.name]: event.target.value,
    });
  };

  const addPrescription = (event) => {
    event.preventDefault();
    setPrescriptionObject([...prescriptionObject, newPrescription]);
    setNewPrescription({
      patientName: "",
      drugName: "",
      dosage: "",
      frequency: "",
      duration: "",
      notes: "",
    });
  };

  return (
    <FormWrapper>
      <FormTitle>{patient.name}'s medication</FormTitle>
      <form onSubmit={addPrescription}>
        <InputField
          type="text"
          placeholder="Medicine"
          id="prescribed_medicine"
          name="drugName"
          value={newPrescription.drugName}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Dosage"
          id="dosage"
          name="dosage"
          value={newPrescription.dosage}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Frequency"
          id="frequency"
          name="frequency"
          value={newPrescription.frequency}
          onChange={handleChange}
        />
        <InputField
          type="text"
          placeholder="Duration"
          id="duration"
          name="duration"
          value={newPrescription.duration}
          onChange={handleChange}
        />
        <TextareaField
          placeholder="Note"
          id="note"
          name="notes"
          value={newPrescription.notes}
          onChange={handleChange}
        />
        <SubmitButton type="submit">Prescribe</SubmitButton>
      </form>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHead>Medicine</TableHead>
              <TableHead>Dosage</TableHead>
              <TableHead>Frequency</TableHead>
              <TableHead>Date Prescribed</TableHead>
              <TableHead>Duration</TableHead>
              <TableHead>Note</TableHead>
            </tr>
          </thead>
          <tbody>
            {prescription.map((prescriptionData, index) => (
              <tr key={index}>
                <TableCell>{prescriptionData.prescribed_medicine}</TableCell>
                <TableCell>{prescriptionData.dosage}</TableCell>
                <TableCell>{prescriptionData.frequency}</TableCell>
                <TableCell>{prescriptionData.prescribed_date}</TableCell>
                <TableCell>{prescriptionData.duration}</TableCell>
                <TableCell>{prescriptionData.note}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </FormWrapper>
  );
};

export default PrescriptionManager;
