import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h3`
  text-align: center;
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 16px;
`;

const VaccinationConsultation = ({ patient }) => {
    const { vaccine } = patient;
    const [vacineObject, setVaccination] = useState([]);
    const [newVaccine, setNewVaccineData] = useState({
        patientName: '',
        vaccineName: '',
        dateAdministered: '',
        dosage: '',
        nextDueDate: '',
        administrationRoute: '',
        sideEffects: '',
        notes: ''
    });

    const handleChange = event => {
        setNewVaccineData({
            ...newVaccine,
            [event.target.name]: event.target.value
        });
    };

    const addVaccination = event => {
        event.preventDefault();
        setVaccination([...vacineObject, newVaccine]);
        setNewVaccineData({
            patientName: '',
            vaccineName: '',
            dateAdministered: '',
            dosage: '',
            nextDueDate: '',
            administrationRoute: '',
            sideEffects: '',
            notes: ''
        });
    };

    return (
        <FormWrapper>
            <FormTitle>{patient.name}'s vaccination</FormTitle>
            <form onSubmit={addVaccination}>
                <InputField
                    type="text"
                    placeholder="Vaccine Name"
                    id="vaccineName"
                    name="vaccineName"
                    value={newVaccine.vaccineName}
                    onChange={handleChange}
                />
                <InputField
                    type="text"
                    placeholder="Date Administered"
                    id="dateAdministered"
                    name="dateAdministered"
                    value={newVaccine.dateAdministered}
                    onChange={handleChange}
                />
                <InputField
                    type="text"
                    placeholder="Dosage"
                    id="dosage"
                    name="dosage"
                    value={newVaccine.dosage}
                    onChange={handleChange}
                />
                <InputField
                    type="text"
                    placeholder="Next Due Date"
                    id="nextDueDate"
                    name="nextDueDate"
                    value={newVaccine.nextDueDate}
                    onChange={handleChange}
                />
                <InputField
                    type="text"
                    placeholder="Administration Route"
                    id="administrationRoute"
                    name="administrationRoute"
                    value={newVaccine.administrationRoute}
                    onChange={handleChange}
                />
                <InputField
                    type="text"
                    placeholder="Side Effects"
                    id="sideEffects"
                    name="sideEffects"
                    value={newVaccine.sideEffects}
                    onChange={handleChange}
                />
                <TextareaField
                    placeholder="Notes"
                    id="notes"
                    name="notes"
                    value={newVaccine.notes}
                    onChange={handleChange}
                />
                <SubmitButton type="submit">Submit</SubmitButton>
            </form>

            <TableContainer>
                <Table>
                    <thead>
                        <tr>
                            <TableHead>Vaccine</TableHead>
                            <TableHead>Administered on</TableHead>
                            <TableHead>Dosage</TableHead>
                            <TableHead>Next Due</TableHead>
                            <TableHead>Side Effects</TableHead>
                            <TableHead>Summary</TableHead>
                        </tr>
                    </thead>
                    <tbody>
                        {vaccine.map((vaccineData, index) => (
                            <tr key={index}>
                                <TableCell>{vaccineData.name}</TableCell>
                                <TableCell>{vaccineData.date}</TableCell>
                                <TableCell>{vaccineData.dosage}</TableCell>
                                <TableCell>{vaccineData.next_due}</TableCell>
                                <TableCell>{vaccineData.side_effect}</TableCell>
                                <TableCell>{vaccineData.summary}</TableCell>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        </FormWrapper>
    );
};

export default VaccinationConsultation;
