// BellCurve.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const BellCurve = ({ patient }) => {
    const { vitals } = patient;
  const chartData = {
    labels: vitals.xValues,
    datasets: [{
      label: 'Heart Rate Distribution',
      data: vitals.yValues,
      fill: true,
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
    }]
  };

  return (
    <div className="bell-curve">
      <h4>Heart Rate Distribution</h4>
      <Line data={chartData} />
    </div>
  );
};

export default BellCurve;
