import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Tooltip, Button, ButtonGroup } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const VitalsHistory = ({ patient }) => {
  const { vitals } = patient; // Assuming vitals data is coming from the patient prop

  const [selectedVitals, setSelectedVitals] = useState({
    weight: true,
    heartRate: false,
    bloodPressure: false,
    temperature: false,
  });

  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isChartVisible, setIsChartVisible] = useState(true);

  const toggleVital = (vital) => {
    setSelectedVitals((prevState) => ({
      ...prevState,
      [vital]: !prevState[vital],
    }));
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible((prevState) => !prevState);
  };

  const toggleChartVisibility = () => {
    setIsChartVisible((prevState) => !prevState);
  };

  const getVitalData = (vital) => vitals.map((vitalEntry) => {
    if (vital === 'weight') {
      return parseInt(vitalEntry.weight, 10);
    }
    if (vital === 'heartRate') {
      return parseInt(vitalEntry.heartRate, 10);
    }
    if (vital === 'bloodPressure') {
      return parseInt(vitalEntry.bloodPressure.split('/')[0], 10); // Only systolic value
    }
    if (vital === 'temperature') {
      return parseFloat(vitalEntry.temperature.replace('°F', ''));
    }
    return 0;
  });

  const chartColors = {
    weight: '#FF7043', // Orange
    heartRate: '#64B5F6', // Blue
    bloodPressure: '#81C784', // Green
    temperature: '#FFD54F', // Yellow
  };

  const chartData = {
    labels: vitals.map((vital) => vital.record_date), // Use record_date for x-axis
    datasets: Object.keys(selectedVitals)
      .filter((key) => selectedVitals[key]) // Include only selected vitals
      .map((key) => ({
        label: `${key.charAt(0).toUpperCase() + key.slice(1)} History`,
        data: getVitalData(key),
        borderColor: chartColors[key],
        backgroundColor: chartColors[key] + '40', // 25% opacity
        fill: true,
      })),
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Units',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} ${tooltipItem.dataset.label}`,
        },
      },
    },
  };

  return (
    <div>
      {/* Chart Visibility Toggle Button */}
      <Tooltip title={isChartVisible ? "Hide Vital Chart" : "Show Vital Chart"} arrow>
        <Button
          onClick={toggleChartVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isChartVisible ? <ExpandLess /> : <ExpandMore />} Vital History Chart
        </Button>
      </Tooltip>

      {/* Vital Selection Buttons */}
      {isChartVisible && (
        <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <ButtonGroup variant="outlined" color="primary">
            <Button
              onClick={() => toggleVital('heartRate')}
              style={{
                backgroundColor: selectedVitals.heartRate ? chartColors.heartRate : 'transparent',
                color: selectedVitals.heartRate ? 'white' : 'initial',
              }}
            >
              Heart Rate
            </Button>
            <Button
              onClick={() => toggleVital('bloodPressure')}
              style={{
                backgroundColor: selectedVitals.bloodPressure ? chartColors.bloodPressure : 'transparent',
                color: selectedVitals.bloodPressure ? 'white' : 'initial',
              }}
            >
              Blood Pressure
            </Button>
            <Button
              onClick={() => toggleVital('temperature')}
              style={{
                backgroundColor: selectedVitals.temperature ? chartColors.temperature : 'transparent',
                color: selectedVitals.temperature ? 'white' : 'initial',
              }}
            >
              Temperature
            </Button>
            <Button
              onClick={() => toggleVital('weight')}
              style={{
                backgroundColor: selectedVitals.weight ? chartColors.weight : 'transparent',
                color: selectedVitals.weight ? 'white' : 'initial',
              }}
            >
              Weight
            </Button>
          </ButtonGroup>
        </div>
      )}

      {/* Chart */}
      {isChartVisible && (
        <div style={{ marginTop: '30px' }}>
          <h6>Vital History Chart</h6>
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
      {/* Table Visibility Toggle Button */}
      <Tooltip title={isHistoryVisible ? "Hide Vital Table" : "Show Vital Table"} arrow>
        <Button
          onClick={toggleHistoryVisibility}
          style={{ marginBottom: '20px', color: '#2196F3' }}
        >
          {isHistoryVisible ? <ExpandLess /> : <ExpandMore />} Vital History Table
        </Button>
      </Tooltip>

      {/* Vital History Table */}
      {isHistoryVisible && (
        <div style={{ marginTop: '30px' }}>
          <table border="1" style={{ marginBottom: '20px' }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Blood Pressure</th>
                <th>Temperature</th>
                <th>Heart Rate</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {vitals.map((vital, index) => (
                <tr key={index}>
                  <td>{vital.record_date}</td>
                  <td>{vital.bloodPressure}</td>
                  <td>{vital.temperature}</td>
                  <td>{vital.heartRate}</td>
                  <td>{vital.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


    </div>
  );
};

export default VitalsHistory;
